<template>
  <div>
    <div class="header">
      <div class="h-box">
        <div class="h-right">
          <div class="logo">
            <img class="logo-img" src="../assets/home/logo.png" alt="" />
            <span>营口市智慧教研平台</span>
          </div>
          <div class="h-nav">
            <div
              class="item"
              :style="{ '--width': item.width }"
              v-for="(item, index) in navList"
              :key="index"
              @click="onClick(item.url)"
            >
              <img class="item-icon" :src="item.icon" alt="" />
              <span>{{ item.name }}</span>
              <div
                :class="['line', $route.name == item.url ? 'active' : '']"
              ></div>
            </div>
          </div>
        </div>
        <div class="h-left">
          <div class="user-box" v-if="userInfo.fk_user_id">
            <div style="cursor: pointer" @click="onClick('user-info')">
              {{ userInfo.nickname || userInfo.mobile }}
            </div>
            <div class="u-line"></div>
            <div style="cursor: pointer" @click="signOut">退出登录</div>
          </div>
          <div class="user-box" v-else>
            <div style="cursor: pointer" @click="onClick('login')">登录</div>
            <div class="u-line"></div>
            <div style="cursor: pointer" @click="onClick('register')">注册</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <keep-alive :include="['teacher_study', 'resourse']">
        <router-view />
      </keep-alive>
    </div>
    <div class="footer">
      <div class="f-content">
        <div class="company">主办单位：{{ businessInfo.platform_name }}</div>
        <div class="information">
          <div class="address p-20">地址：{{ businessInfo.address }}</div>
          <div class="phone p-20">联系方式：{{ businessInfo.tel }}</div>
          <div class="visitNum">网站访问量：{{ businessInfo.read_num }}</div>
        </div>
        <div class="web-information">
          <div class="record-number p-20" @click="open">
            {{ businessInfo.record_number }}
          </div>
          <div class="postal-code p-20">邮编：{{ businessInfo.postcode }}</div>
          <div class="email p-20">Email：{{ businessInfo.email }}</div>
          <div class="website">网址：{{ businessInfo.web_url }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      navList: [
        {
          name: "首页",
          icon: require("../assets/home/ic_4.png"),
          width: "60px",
          url: "/",
        },
        {
          name: "教育资源",
          icon: require("../assets/home/ic_3.png"),
          width: "90px",
          url: "teacher_study",
        },
        {
          name: "云端直播",
          icon: require("../assets/home/ic_9.png"),
          width: "90px",
          url: "teacher_live",
        },
        {
          name: "信息资讯",
          icon: require("../assets/home/ic_5.png"),
          width: "90px",
          url: "news",
        },
        {
          name: "证书查询",
          icon: require("../assets/home/ic_7.png"),
          width: "90px",
          url: "certificatesearch",
        },
        {
          name: "资源分享",
          icon: require("../assets/home/ic_8.png"),
          width: "90px",
          url: "resourse",
        },
      ],
      navCurrent: 0,
    };
  },
  computed: {
    ...mapState(["businessInfo"]),
  },
  mounted() {
    this.$getBusiness({});
  },
  methods: {
    ...mapActions(["$getBusiness"]),
    onClick(url) {
      if (url == this.$route.name) {
        if (this.$route.query.classID) {
          this.$router.replace({ query: {} });
        }
        location.reload();
      }
      this.$router.push({
        name: url,
        params: {},
      });
    },
    open() {
      window.open("https://beian.miit.gov.cn");
    },
    signOut() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then((res) => {
        if (res == "confirm") {
          this.$uStore("userInfo", {});
          if (this.$route.path == "/user-info") {
            this.$router.replace("/");
          } else if (this.$route.path == "/certificate_all") {
            this.$router.replace("/");
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d2088;
  position: relative;
  z-index: 10;
  .h-right {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .h-box {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      margin-right: 50px;
      letter-spacing: 1px;
      .logo-img {
        width: 54px;
        height: 54px;
        margin-right: 12px;
      }
    }
    .h-nav {
      height: 100%;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 1px;
        font-size: 16px;
        font-weight: bold;
        color: #f5f5f5;
        position: relative;
        width: 118px;
        height: 100%;
        cursor: pointer;
        .line {
          width: 0;
          height: 2px;
          background-color: #fff;
          position: absolute;
          bottom: 20px;
          &.active {
            width: var(--width);
            transition: width 0.4s ease;
          }
        }
        .item-icon {
          width: 18px;
          height: 17px;
          margin-right: 6px;
        }
      }
      .item:hover .line {
        width: var(--width);
        transition: width 0.4s ease;
      }
    }
  }
  .h-left {
    .user-box {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      .u-line {
        width: 2px;
        height: 18px;
        background-color: #fff;
        margin: 0 8px;
      }
    }
  }
}
.content {
  width: 100%;
  background-color: #f5f5f5;
  min-height: calc(100vh - 130px - 110px);
}
.footer {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1d2088;
  position: relative;
  z-index: 10;
  .f-content {
    width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    color: #fff;
    .company {
      line-height: 34px;
      letter-spacing: 1px;
    }
    .record-number {
      cursor: pointer;
    }
    .information {
      display: flex;
      align-items: center;
      line-height: 34px;
    }
    .web-information {
      display: flex;
      align-items: center;
      line-height: 34px;
    }
  }
}
.p-20 {
  padding-right: 20px;
}
</style>
