import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import Api from "@/api";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        businessId: 7,
        weburl: 'http://www.ykzhjy.com',
        businessInfo: {},
        kong: 'https://eduapi.114kaili.com/Content/images/no_img.png',
        userInfo: {}
    },
    mutations: {
        $uStore(state, payload) {
            // 判断是否多层级调用，state中为对象存在的情况，诸如user.info.score = 1
            let nameArr = payload.name.split('.')
            let len = nameArr.length
            if (len >= 2) {
                let obj = state[nameArr[0]]
                for (let i = 1; i < len - 1; i++) {
                    obj = obj[nameArr[i]]
                }
                obj[nameArr[len - 1]] = payload.value
            } else {
                // 单层级变量，在state就是一个普通变量的情况
                state[payload.name] = payload.value
            }
        },
    },
    actions: {
        async $getBusiness({ commit }, payload) {
            const obj = await Api.BusinessInfo(payload)
            commit('$uStore', {
                name: 'businessInfo',
                value: obj.data
            })
        }
    },
    modules: {},
    plugins: [
        createPersistedState({
            paths: ['businessInfo', 'userInfo'],
            storage: {
                getItem: (key) => localStorage.getItem(key),
                setItem: (key, value) => localStorage.setItem(key, value),
                removeItem: (key) => localStorage.removeItem(key),
            },
        }),
    ],
})