import moment from "moment"
import APi from '@/api'
const mixin = {
    computed: {
        userInfo() {
            return this.$store.state.userInfo
        },
        kongimg() {
            return this.$store.state.kong
        }
    },
    filters: {
        FormData(time, type = 'YYYY-MM-DD HH:mm:ss') {
            return moment(time).format(type)
        }
    },
    beforeCreate() {
        // 将vuex方法挂在到$u中
        // 使用方法为：如果要修改vuex的state中的user.name变量为"史诗" => this.$u.vuex('user.name', '史诗')
        // 如果要修改vuex的state的version变量为1.0.1 => this.$u.vuex('version', '1.0.1')
        this.$uStore = (name, value) => {
            this.$store.commit('$uStore', {
                name,
                value,
            })
        }
    },
    methods: {
        checkLogin(url, params, tip = '当前未登录，是否跳转进行登录') {
            this.$alert(tip, {
                confirmButtonText: "确定",
                showCancelButton: true,
                cancelButtonText: "取消",
                callback: (action) => {
                    if (action == "confirm") {
                        this.$router.push({
                            name: url,
                            query: params,
                        });
                    }
                },
            });
        },
        formatData(time, type = 'YYYY-MM-DD HH:mm:ss') {
            return moment(time).format(type)
        },
        getTitle(type) {
            APi.PageTitle({
                type: type,
            }).then(res => {
                document.title = res.data.title;
            })
        }
    }
}
export default mixin