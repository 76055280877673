import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import uMixin from './store/$u.mixin'
import 'element-ui/lib/theme-chalk/index.css';
import VEmojiPicker from 'v-emoji-picker';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.use(VEmojiPicker);
import 'normalize.css'
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.mixin(uMixin)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')