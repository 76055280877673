import axios from 'axios'
import store from '@/store'
import config from '@/config'
import md5 from 'js-md5';
const instail = axios.create({
        baseURL: config.baseUrl,
        timeout: config.timeout,
        headers: {
            'Content-Type': config.contentType,
        },
    })
    //请求前拦截
instail.interceptors.request.use(config => {
        config.data.fk_business_id = store.state.businessId
        let secret = 'EDF56C7B21B2EAB0'
        let time = new Date().getTime()
        config.headers['Timestamp'] = time
        config.headers['AccessToken'] = md5(secret + time)
        return config
    }, err => {
        return Promise.reject(err);
    })
    //请求后拦截
instail.interceptors.response.use(({ data, status }) => {
    if (status == 200) {
        return data
    }
}, err => {
    // const response = err.response;
    // if (response.status === 401) {
    //     Message.error("登录已过期，请重新登录！");
    //     util.goLogin();
    // } else if (response.status === 403) {
    //     $router.push({
    //         name: '403'
    //     });
    // } else {

    // }
    return Promise.reject(err);
})
export default instail