import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    children: [{
            path: '/',
            name: '/',
            component: () =>
                import ('../views/home')
        },
        {
            path: '/teacher_study',
            name: 'teacher_study',
            component: () =>
                import ('../views/teacherTraining')
        },
        {
            path: '/teacher_live',
            name: 'teacher_live',
            component: () =>
                import ('../views/teacherTraining/live')
        },
        {
            path: '/study_detail',
            name: 'study_detail',
            component: () =>
                import ('../views/teacherTraining/detail')
        },
        {
            path: '/news',
            name: 'news',
            component: () =>
                import ('../views/realTimeInfo')
        },
        {
            path: '/news_all',
            name: 'news_all',
            component: () =>
                import ('../views/realTimeInfo/list')
        },
        {
            path: '/news_detail',
            name: 'news_detail',
            component: () =>
                import ('../views/realTimeInfo/detail')
        },
        {
            path: '/resourse',
            name: 'resourse',
            component: () =>
                import ('../views/resourceSharing')
        },
        {
            path: '/searchList',
            name: 'searchList',
            component: () =>
                import ('../views/resourceSharing/searchList')
        },
        {
            path: '/resourse_detail',
            name: 'resourse_detail',
            component: () =>
                import ('../views/resourceSharing/detail')
        },
        {
            path: '/certificate',
            name: 'certificate',
            component: () =>
                import ('../views/certificate'),
            children: [{
                    path: '/certificate',
                    name: 'certificatesearch',
                    component: () =>
                        import ('../views/certificate/search')
                },
                {
                    path: '/certificate_all',
                    name: 'certificate_all',
                    component: () =>
                        import ('../views/certificate/searchList')
                },
                {
                    path: '/cert_detail',
                    name: 'cert_detail',
                    component: () =>
                        import ('../views/certificate/teacherdetail')
                }
            ]
        },
        {
            path: '/user-info',
            name: 'user-info',
            component: () =>
                import ('../views/user/user-index'),
        },
        {
            path: '/user',
            name: 'user',
            component: () =>
                import ('../views/user'),
            children: [{
                path: '/login',
                name: 'login',
                component: () =>
                    import ('../views/user/login')
            }, {
                path: '/register',
                name: 'register',
                component: () =>
                    import ('../views/user/register')
            }, {
                path: '/findpassword',
                name: 'findpassword',
                component: () =>
                    import ('../views/user/reset')
            }]
        },
    ]
}]

const router = new VueRouter({
        // mode: 'history',
        mode: 'hash',
        routes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            } else {
                return { x: 0, y: 0 }
            }
        },
    })
    // router.beforeEach((to, from, next) => {

// })
export default router