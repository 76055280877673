import require from '../../utils/request'

function Register(params) {
    return require({
        url: '/WebApi/Register',
        method: 'POST',
        data: params
    })
}

function SmsCode(params) {
    return require({
        url: '/WebApi/SmsCode',
        method: 'POST',
        data: params
    })
}
//删除用户
function DelMobileUser(params) {
    return require({
        url: '/WebApi/DelMobileUser',
        method: 'POST',
        data: params
    })
}

function ResetPwd(params) {
    return require({
        url: '/WebApi/ResetPwd',
        method: 'POST',
        data: params
    })
}

function Login(params) {
    return require({
        url: '/WebApi/Login',
        method: 'POST',
        data: params
    })
}

function BusinessInfo(params) {
    return require({
        url: '/WebApi/BusinessInfo',
        method: 'POST',
        data: params
    })
}

function Banner(params) {
    return require({
        url: '/WebApi/Banner',
        method: 'POST',
        data: params
    })
}

function Course(params) {
    return require({
        url: '/WebApi/Course',
        method: 'POST',
        data: params
    })
}

function News(params) {
    return require({
        url: '/WebApi/News',
        method: 'POST',
        data: params
    })
}

function Resource(params) {
    return require({
        url: '/WebApi/Resource',
        method: 'POST',
        data: params
    })
}

function Category(params) {
    return require({
        url: '/WebApi/Category',
        method: 'POST',
        data: params
    })
}

function Teacher(params) {
    return require({
        url: '/WebApi/Teacher',
        method: 'POST',
        data: params
    })
}

function NewsDetail(params) {
    return require({
        url: '/WebApi/NewsDetail',
        method: 'POST',
        data: params
    })
}

function CourseDetail(params) {
    return require({
        url: '/WebApi/CourseDetail',
        method: 'POST',
        data: params
    })
}

function SubmitComment(params) {
    return require({
        url: '/WebApi/SubmitComment',
        method: 'POST',
        data: params
    })
}

function Comment(params) {
    return require({
        url: '/WebApi/Comment',
        method: 'POST',
        data: params
    })
}

function Fabulous(params) {
    return require({
        url: '/WebApi/Fabulous',
        method: 'POST',
        data: params
    })
}

function CourseChapter(params) {
    return require({
        url: '/WebApi/CourseChapter',
        method: 'POST',
        data: params
    })
}

function TeacherDetail(params) {
    return require({
        url: '/WebApi/TeacherDetail',
        method: 'POST',
        data: params
    })
}

function CertDetail(params) {
    return require({
        url: '/WebApi/CertDetail',
        method: 'POST',
        data: params
    })
}

function ResourceDetail(params) {
    return require({
        url: '/WebApi/ResourceDetail',
        method: 'POST',
        data: params
    })
}

function ResourceFile(params) {
    return require({
        url: '/WebApi/ResourceFile',
        method: 'POST',
        data: params
    })
}

function PageTitle(params) {
    return require({
        url: '/WebApi/PageTitle',
        method: 'POST',
        data: params
    })
}

function UserDetail(params) {
    return require({
        url: '/WebApi/UserDetail',
        method: 'POST',
        data: params
    })
}

function ChangeMobile(params) {
    return require({
        url: '/WebApi/ChangeMobile',
        method: 'POST',
        data: params
    })
}

function ModifyUser(params) {
    return require({
        url: '/WebApi/ModifyUser',
        method: 'POST',
        data: params
    })
}

function UpdateTeacher(params) {
    return require({
        url: '/WebApi/UpdateTeacher',
        method: 'POST',
        data: params
    })
}

function UpdatePwd(params) {
    return require({
        url: '/WebApi/UpdatePwd',
        method: 'POST',
        data: params
    })
}

function UpdateHeadimgurl(params) {
    return require({
        url: '/WebApi/UpdateHeadimgurl',
        method: 'POST',
        data: params
    })
}

function Collection(params) {
    return require({
        url: '/WebApi/Collection',
        method: 'POST',
        data: params
    })
}

function CancelFabulous(params) {
    return require({
        url: '/WebApi/CancelFabulous',
        method: 'POST',
        data: params
    })
}

function UserComment(params) {
    return require({
        url: '/WebApi/UserComment',
        method: 'POST',
        data: params
    })
}

function Browse(params) {
    return require({
        url: '/WebApi/Browse',
        method: 'POST',
        data: params
    })
}

function GetDownFile(params, opt = {}) {
    return require({
        url: '/WebApi/GetDownFile',
        method: 'POST',
        data: params,
        ...opt
    })
}

function UpdateDown(params) {
    return require({
        url: '/WebApi/UpdateDown',
        method: 'POST',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: "blob",
    })
}

function UploadFile(params) {
    return require({
        url: '/WebApi/UploadFile',
        method: 'POST',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}

function CourseGrade(params) {
    return require({
        url: '/WebApi/CourseGrade',
        method: 'POST',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}

function CourseSubject(params) {
    return require({
        url: '/WebApi/CourseSubject',
        method: 'POST',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}

function ResourceGrade(params) {
    return require({
        url: '/WebApi/ResourceGrade',
        method: 'POST',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}

function ResourceSubject(params) {
    return require({
        url: '/WebApi/ResourceSubject',
        method: 'POST',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}

export default {
    Register,
    BusinessInfo,
    Banner,
    Course,
    SmsCode,
    Login,
    DelMobileUser,
    ResetPwd,
    News,
    Resource,
    Category,
    Teacher,
    NewsDetail,
    CourseDetail,
    SubmitComment,
    Comment,
    Fabulous,
    CourseChapter,
    TeacherDetail,
    CertDetail,
    ResourceDetail,
    ResourceFile,
    PageTitle,
    UserDetail,
    ChangeMobile,
    ModifyUser,
    UpdateTeacher,
    UpdatePwd,
    UpdateHeadimgurl,
    Collection,
    CancelFabulous,
    UserComment,
    Browse,
    GetDownFile,
    UpdateDown,
    UploadFile,
    CourseGrade,
    CourseSubject,
    ResourceGrade,
    ResourceSubject
}